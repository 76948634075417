// yogacompare
// (c) 2016 by Lenard Gunda @lenardg

function formatMinute(minute) {
    if ( minute < 10 ) {
        return "0" + minute.toString();
    }
    return minute.toString();
}

function Notebook(data,helps,sorts,render,parent) {
    this.helps = helps;
    this.sorts = sorts;
    this.render = render;
    this.comparer = parent;
    _.extend(this, data);
    
    var that = this;
    _.extend(this, {
        isVisible: ko.observable(this.visible),
        isBest: ko.computed(function() {
                        
        }),
        isWorst: ko.computed(function() {
            
        }),
        battery_life_formatted: ko.computed(function() {
            return Math.floor(that.battery_life / 60) + ":" + formatMinute(that.battery_life % 60);  
        }),
    });
}

function templatedComponent(params) {
    var that = this;
    _.extend(this, {
        renderInfo: params.renderInfo,
    });
    
    this.hasHelp = ko.computed(function() {
            return !!that.comparer && !!that.comparer.helps()[that.renderInfo.property];
        });
    this.generalHelp = ko.computed(function() {
            if ( !that.hasHelp() ) { return null; }
            return that.comparer.helps()[that.renderInfo.property]._general;
        });    
    this.generalHelpTitle = ko.computed(function() {
            if ( !that.hasHelp() ) { return null; }
            return that.comparer.helps()[that.renderInfo.property]._title;
        });
}

function comparerComponent(params) {
    var that = this;
    _.extend(this, {
        comparer: params.comparer,
        renderInfo: params.renderInfo,
        setSort: function() {
            this.comparer.setSort(this.renderInfo.property);
        },            
        currentSort: ko.computed(function() { return params.comparer.currentSort(); }),
        currentSortDirection: ko.computed(function() { return params.comparer.currentSortDirection(); })
    });
    
    this.hasHelp = ko.computed(function() {
            return !!that.comparer && !!that.comparer.helps()[that.renderInfo.property];
        });
    this.generalHelp = ko.computed(function() {
            if ( !that.hasHelp() ) { return null; }
            return that.comparer.helps()[that.renderInfo.property]._general;
        });    
    this.generalHelpTitle = ko.computed(function() {
            if ( !that.hasHelp() ) { return null; }
            return that.comparer.helps()[that.renderInfo.property]._title;
        });
    this.bestValue = ko.computed(function() {
        
    });
}

ko.components.register('sortable-numeric', {
    viewModel: comparerComponent,
    template: { element: "template-sortable-numeric" }
});

ko.components.register('sortable-globalized-numeric', {
    viewModel: comparerComponent,
    template: { element: "template-sortable-globalized-numeric" }
});

ko.components.register('sortable-boolean', {
    viewModel: comparerComponent,
    template: { element: "template-sortable-boolean" }
});

ko.components.register('show-reviews', {
    viewModel: templatedComponent,
    template: { element: "template-reviews" }
});


ko.bindingHandlers.popover = {
    init: function(element, valueAccessor, allBindings) {
        $(element).popover();
    }
}


function YogaCompare() {
    var that = this;

    this.name = "Yoga Comparison - Interactive table";
    this.isLoading = ko.observable(true);
    this.isError = ko.observable(false);
    this.country = ko.observable(0);

    this.helps = ko.observableArray([]);
    this.sorts = ko.observableArray([]);
    this.render = ko.observableArray([]);
    this.allYogas = ko.observableArray([]);
    
    this.currentSort = ko.observable("none");
    this.currentSortDirection = ko.observable(1);
        
    this.yogas = ko.computed(function() {
        var sortRule;
        if ( that.currentSort() == "none" || !that.sorts()[that.currentSort()] ) {
            sortRule = undefined;
        }
        else {       
            sortRule = that.sorts()[that.currentSort()];
        }

        return _.chain(that.allYogas())
                .filter(function(a) {
                    return a.isVisible();
                })
                .sort(function(a,b) {
                    if ( !sortRule ) return -1;
                    if ( sortRule.sort == "numeric") {
                        return sortRule_numeric(a[that.currentSort()], b[that.currentSort()]);
                    }
                    if ( sortRule.sort == "boolean") {
                        return sortRule_boolean(a[that.currentSort()], b[that.currentSort()]);
                    }
                    if ( sortRule.sort == "enum") {
                        return sortRule_enum(a[that.currentSort()], b[that.currentSort()], sortRule.values);
                    }
                })
                .value();
    });
    
    this.yogas.subscribe(function(newValue) {
        updateHash();
    });
    
    this.unitWeight = ["kg", "lbs"];
    this.unitLength = ["mm", "inch"];
    
    this.canSort = function(prop) {
        return !!that.sorts[prop];
    }
    
    this.setSort = function(prop) {
        if ( that.currentSort() == prop ) {
            if ( that.currentSortDirection() == 1) {
                that.currentSortDirection(-1);
            }
            else {
                that.currentSortDirection(1);                
            }
        }
        else {
            that.currentSort(prop);
            that.currentSortDirection(1);                
        }        
    }
    
    this.showConsumer = function() {
        _.each(that.allYogas(), function(notebook) {
            if ( !notebook.thinkpad ) {
                notebook.isVisible(true);
            }
            else {
                notebook.isVisible(false);
            }
        });
        updateHash();
    }
    
    this.showThinkpad = function() {
        _.each(that.allYogas(), function(notebook) {
            if ( !!notebook.thinkpad ) {
                notebook.isVisible(true);
            }
            else {
                notebook.isVisible(false);
            }
        });
        updateHash();
    }

    this.showAll = function() {
        _.each(that.allYogas(), function(notebook) {
            notebook.isVisible(true);
        });
        updateHash();
    }

    this.showNone = function() {
        _.each(that.allYogas(), function(notebook) {
            notebook.isVisible(false);
        });
        updateHash();
    }
    
    function updateHash() {
        var hash = "";
        _.each(that.allYogas(), function(notebook) {
            if ( notebook.isVisible() ) {
                if ( hash.length > 0 ) { hash += ","; }
                hash += notebook.id;
            }
        });
        window.location.hash = hash;
    }
    
    function checkHash(notebooks) {
        if ( !window.location.hash || $.trim(window.location.hash) == "" ) { return; }
        
        var hash = window.location.hash;
        if ( hash.length > 1 && hash[0] == '#') {
            hash = hash.substr(1);
        }
        var ids = hash.split(",");
        
        _.each(notebooks, function(nb) {
             if ( _.indexOf(ids, nb.id) == -1 ) {
                 nb.visible = false;
             } else {
                 nb.visible = true;                 
             }
        });
    }

    function sortRule_numeric(a,b) {
        if ( a < b ) {
            return -1 * that.currentSortDirection();
        }
        else {
            return 1 * that.currentSortDirection();
        }
    }

    function sortRule_boolean(a,b) {
        if ( !!a || (!a && !b) ) {
            return 1 * that.currentSortDirection();
        }
        else {
            return -1 * that.currentSortDirection();
        }
    }

    function sortRule_enum(a,b,enums) {
        var ia, ib;
        if ( _.isArray(a)) {
            ia = _.chain(a).map(function(aa) { return _.indexOf(enums,aa);}).max();
        }
        else {
            ia  = _.indexOf(enums,a);
        }
        
        if ( _.isArray(b)) {
            ib = _.chain(b).map(function(bb) { return _.indexOf(enums,bb);}).max();
        }
        else {
            ib  = _.indexOf(enums,b);
        }
        return sortRule_numeric(ia,ib);
    }
    
    function prepareYoga(yoga) {
        return new Notebook(yoga, that.helps(), that.sorts(), that.render(), that );        
    }
    
    $.ajax({
        url: "yoga.json",
        method: "GET"
    }).done(function(d) {
        that.helps(d.help);
        that.sorts(d.sort);
        
        checkHash(d.yogas);
        
        var notebooks = _.map(d.yogas, prepareYoga);
                
        that.allYogas(notebooks);
        that.render(d.render);
    }).fail(function(jqxhr,textstatus) {
        that.isError(true);
    }).always(function() {
        that.isLoading(false);
    });
}

var viewModel = new YogaCompare();
ko.applyBindings(viewModel);

$(function() {
    $(window).scroll(function() {
        
        var floatingTable = $(".floater");
        var originalTable = floatingTable.parent().find("thead:first-child");
        var floatingCells = floatingTable.find("th");
        var originalCells = originalTable.find("th");
        
        for ( var i = 0; i < floatingCells.length; ++i ) {
            var floatingCell = $(floatingCells[i]);
            var originalCell = $(originalCells[i]);
            
            floatingCell.width(originalCell.width());
        }
        
        var cutoffPoint = $("table.yogatable").offset().top;
        var y = $(window).scrollTop();
        if ( y > cutoffPoint ) {
            $(".initially-hidden").show();
        }       
        else {
            $(".initially-hidden").hide();            
        }
    });
});